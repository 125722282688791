import React from "react";
import PropTypes from "prop-types";
import { EntryAnimationWrapper } from "../EntryAnimationWrapper";

export function CreateAccountWrapper({ children }) {
  return <EntryAnimationWrapper>{children}</EntryAnimationWrapper>;
}

CreateAccountWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};
